import React from 'react'
import { graphql } from 'gatsby'

// Elements
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const PageTemplate = ({
  data: {
    page: { wordpress_id: wpId, path, yoast_meta: yoast, acf },
  },
  location,
  pageContext
}) => {
  return (
    <Layout location={location}>
      <SEO yoast={yoast} path={path} />

      <FlexContentHandler path={path} pageContext={pageContext} fields={acf.content_vacature} wpId={wpId} />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpVacature(wordpress_id: { eq: $wordpress_id }) {
      ...generalVacatureFragment
    }
  }
`

export default PageTemplate
